<div class="container-fluid" *ngIf="ShowOverlappingOutages == false">
    <div class="row">
        <div class="col-md-12 font-weight-bold ">All times are in BST</div>
    </div>
    <div class="row">
        <div class="col-md-12">&nbsp;</div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="outage.id != 0" [hasCustomIcon]="false" [isRequired]="true"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="BMUs">
                <select njFormField id="BMUs" [(ngModel)]="outage.bmu" (ngModelChange)="BMU_Changed()">
                    <option *ngFor="let unit of BMUList" value='{{unit}}'>{{unit}}</option>
                </select>
                <ng-container njFormLabel>Affected Unit</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <nj-form-item 
                [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" [isRequired]="true"
                inputId="EventAvailabilityType">
                <select njFormField id="EventAvailabilityType" [(ngModel)]="outage.availabilityType"
                    (change)="ValidateGenerateRemit()">
                    <option *ngFor="let availabilityType of AvailabilityTypesList" value='{{availabilityType.Id}}'>
                        {{availabilityType.Description}}</option>
                </select>
                <ng-container njFormLabel>Availability Type</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
                
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="" inputId="EventStartTime" [isRequired]="true">
                <input type="datetime-local" id="EventStartTime" njFormField [(ngModel)]="outage.fromDate" />
                <ng-container njFormLabel>Event Start Time</ng-container>
                <ng-container njFormSubscript></ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="" inputId="EventEndTime" [isRequired]="true">
                <input type="datetime-local" id="EventEndTime" njFormField [(ngModel)]="outage.toDate" />
                <ng-container njFormLabel>Event End Time</ng-container>
                <ng-container njFormSubscript></ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="IsTritonOrMorwayEast == true" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true"
                inputId="EventAvailabilityCode" [isRequired]="true">
                <select njFormField id="EventAvailabilityCode" [(ngModel)]="outage.code" (ngModelChange)="AvailabilityCode_Changed()">
                    <option *ngFor="let code of EventAvailabilityCodes" [value]="code">{{code}}</option>
                </select>
                <ng-container njFormLabel>Code</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
        <div class="col-md-1">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="AvailabilityValue" [isRequired]="true">
                <input id="AvailabilityValue" type="number" [(ngModel)]="outage.availabilityValue" njFormField min="0" [disabled]="outage.code.toString() == 'OU' && IsTritonOrMorwayEast == false"/>
                <ng-container njFormLabel>Value</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row  py-md-3" [style]="'margin-top:5px;'" *ngIf="IsTritonOrMorwayEast == false">
        <div class="col-md-5">
            <nj-checkbox
            inputId="chkIsGenerationREMIT"
            [hasError]="false"
            [errorMessage]="'Error message'"
            [(ngModel)]="IsGenerationREMIT"
            [isDisabled]="outage.code == 'GE' || outage.id != 0"
            >
            Create Generation REMIT
            </nj-checkbox>
        </div>
    </div>
    <div class="row" *ngIf="IsTritonOrMorwayEast == false && EnableConsumptionREMIT == true">
        <div class="col-md-2">
            <nj-checkbox
            inputId="chkIsConsumptionREMIT"
            [hasError]="false"
            [errorMessage]="'Error message'"
            [(ngModel)]="IsConsumptionREMIT"
            [isDisabled]="outage.code == 'GE' || outage.id != 0"
            >
            Create Consumption REMIT
            </nj-checkbox>    
        </div>
        <div class="col-md-10" id="EventATGeneratesREMIT" [style]="'font-weight: bold; color:rgb(255, 6, 6);'" 
        [style.display] = "outage.code != 'GE' && IsConsumptionREMIT == true && IsGenerationREMIT == false ? '' : 'none'">
            If intention is to only submit a consumption REMIT, please change “Code” to “GE”.<br>
            If intention is to submit both consumption and generation REMIT, please tick “Create Generation REMIT” in addition to “Create Consumption REMIT”
        </div>
    </div>
    <div class="row"[style.display] = "outage.code != 'GE' && IsConsumptionREMIT == true && IsGenerationREMIT == false ? '' : 'none'">
        <div class="col-md-6">
            <span>&nbsp;</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <span>Please enter the reason for the outage below. To enter a reason not included in the "Reason Summary" dropdown, pick <b>x Free Text x</b> option and populate the 'Cause' box below</span>
        </div>
    </div>
    <div class="row py-md-3">
        <div class="col-md-5">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="EventReason" [isRequired]="true">
                <select njFormField id="EventReason" [(ngModel)]="outage.reasonSummary" (change)="ValidateReason()">
                    <option *ngFor="let reasonSummary of ReasonSummariesList" value='{{reasonSummary}}'>
                        {{reasonSummary}}</option>
                </select>
                <ng-container njFormLabel>Reason Summary</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="outage.reasonSummary != 'x Free Text x'" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="EventCause">
                <textarea id="EventCause" [(ngModel)]="outage.cause" njFormField maxlength="1000"></textarea>
                <ng-container njFormLabel>Cause</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false" [isRequired]="IsFHC == true"
                [isFloatingLabel]="true" inputId="EventInternalNotes">
                <textarea id="EventInternalNotes" [(ngModel)]="outage.internalNotes" njFormField njFormField maxlength="510"></textarea>
                <ng-container njFormLabel>Internal Notes (not for REMIT or ETR)</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="EventDurationUncertainty">
                <textarea id="EventDurationUncertainty" [(ngModel)]="outage.durationUncertainty" njFormField maxlength="1000"></textarea>
                <ng-container njFormLabel>Duration Uncertainty</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    @if (IsGenerationREMIT == true) {
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="RelatedInformationGen">
                <textarea id="RelatedInformationGen" [(ngModel)]="outage.relatedInformationGen" njFormField maxlength="1000"></textarea>
                <ng-container njFormLabel>GENERATION REMIT - Related Information</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    }
    @if (IsConsumptionREMIT == true) {
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" inputId="RelatedInformationCons">
                <textarea id="RelatedInformationCons" [(ngModel)]="outage.relatedInformationCons" njFormField maxlength="1000"></textarea>
                <ng-container njFormLabel>CONSUMPTION REMIT - Related Information</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    }
    <div class="row">
        <div class="col-md-6">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="EventNotifiedBy">
                <select njFormField id="EventNotifiedBy" [(ngModel)]="outage.notifiedBy">
                    <option *ngFor="let notifier of NotifiersList" value='{{notifier}}'>
                        {{notifier}}</option>
                </select>
                <ng-container njFormLabel>Notified By</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row" *ngIf="IsTritonUser == false">
        <div class="col-md-3">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="" inputId="EventStartTime" [isRequired]="true">
                <input type="datetime-local" id="EventStartTime" njFormField [(ngModel)]="outage.notificationDateTime"/>
                <ng-container njFormLabel>Notification Date</ng-container>
                <ng-container njFormSubscript></ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row" *ngIf="IsTritonUser == false">
        <div class="col-md-4">
            <nj-form-item [hasError]="false" [hasHint]="false" [isDisabled]="false" [hasCustomIcon]="false"
                [isFloatingLabel]="true" iconName="keyboard_arrow_down" [isSelect]="true" inputId="EventNotificationMethod"
                [isRequired]="true"
                >
                <select njFormField id="EventNotificationMethod" [(ngModel)]="outage.notificationMethod">
                    <option *ngFor="let notificationMethod of NotificationMethodsList" value='{{notificationMethod}}'>
                        {{notificationMethod}}</option>
                </select>
                <ng-container njFormLabel>Notification Method</ng-container>
                <ng-container njFormSubscript>
                </ng-container>
            </nj-form-item>
        </div>
    </div>
    <div class="row"><div class="col-md-3">&nbsp;</div></div>
    <div class="row">
        <div class="col-md-2">
            <nj-button size="normal" type="button" [isDisabled]="IsMissingData() || IsSaving" icon="" [hasCustomIcon]="false" 
                (buttonClick)="SaveAvailability(false, true)">
                Submit
            </nj-button>
        </div>
        @if (outage.id != 0 && UserCanResubmitREMIT == true)
        {
        <div class="col-md-3">
            <nj-button size="normal" type="button" [isDisabled]="IsMissingData() || IsSaving" icon="" [hasCustomIcon]="false" 
                (buttonClick)="SaveAvailability(false, false)">
                Submit without REMIT
            </nj-button>
        </div>
        }
        <div class="col-md-3" *ngIf="outage.id == 0">
            <nj-button size="normal" type="button" [isDisabled]="IsMissingData() || IsSaving" icon="" [hasCustomIcon]="false" 
                (buttonClick)="SaveAvailability(true, true)">
                Submit and Copy to New
            </nj-button>
        </div>
        <div class="col-md-3">
            <nj-button size="normal" type="button" [isDisabled]="false" icon="" [hasCustomIcon]="false"
                (buttonClick)="BackToRemits()">
                Cancel
            </nj-button>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>
<app-outage-overlap  *ngIf="ShowOverlappingOutages == true" 
    [OverlapOutagesData]="OverlappingOutages"
    (IsAccepted)="OverlappingDialogResult($event)"
    ></app-outage-overlap>